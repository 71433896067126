<template>
  <v-row
    id="login"
    class="layer primary"
    align="center"
    justify="center"
    no-gutters
  >

    <alerts absolute/>

    <v-sheet
      color="transparent"
      class="ma-4 pa-4"
      width="100%"
      max-width="400"
    >

      <logo
        class="mb-4"
        height="100"
        color="black"
        show-version
      />

      <v-card class="pa-4 mb-6">

      <v-alert
        v-if="!success"
        v-html="$t('resetPass.info')"
        class="body-2"
        color="black"
        outlined
      />

      <v-form
        ref="form"
        v-if="!success"
        @submit.prevent="onSubmit"
      >

        <text-field
          v-show="!$route.query.code"
          autocomplete="code"
          background-color="white"
          v-model="code"
          :label="$t('inputs.code.label')"
          :rules="rules.code"
          fluid
        />

        <text-field
          v-show="!$route.query.email"
          autocomplete="username"
          background-color="white"
          v-model="email"
          :label="$t('inputs.email.label')"
          :rules="rules.email"
          fluid
        />

        <password-field
          autocomplete="new-password"
          background-color="white"
          v-model="password"
          :label="$t('inputs.password.label')"
          :rules="rules.password"
          fluid show-password
        />

        <password-field
          name="RepeatPassword"
          autocomplete="off"
          background-color="white"
          v-model="password2"
          :label="$t('inputs.repeatPassword.label')"
          :rules="rules.password2"
          fluid show-password
        />

        <btn
          type="submit"
          class="caption mt-2 mb-3"
          color="black"
          :loading="loading"
          block dark
        >
          {{ $t('btn.send') }}
        </btn>

      </v-form>

      <v-alert
        v-else
        v-html="$t('alerts.resetPass')"
        type="success"
        color="black"
      />

      </v-card>

      <router-link class="caption black--text" :to="$lp('/login')">
        {{ $t('btn.login') }}
      </router-link>

    </v-sheet>
  </v-row>
</template>

<script>
import Alerts from '@/components/Alerts';
import TextField from '@/components/form/TextField';
import PasswordField from '@/components/form/PasswordField';
import { required, isEmail, minMatch, minLength, sameAs } from '@/utils/rules';
import { mapActions } from 'vuex';

export default {
  name: 'RecoveryPass',
  head: vm => ({ title: vm.$i18n.t('resetPass.title') }),
  auth: 'guess',
  components: { TextField, PasswordField, Alerts },
  data: () => ({
    loading: false,
    code: null,
    email: '',
    password: '',
    password2: '',
    success: ''
  }),
  computed: {
    rules() {
      return {
        code: [
          required( this.$t('inputs.code.rules.required'))
        ],
        email: [
          required( this.$t('inputs.email.rules.required')),
          isEmail( this.$t('inputs.email.rules.isEmail'))
        ],
        password: [
          required( this.$t('inputs.password.rules.required2')),
          minMatch(/[a-z]/g, 1, this.$t('inputs.password.rules.lower',{ n:1 })),
          minMatch(/[A-Z]/g, 1, this.$t('inputs.password.rules.upper',{ n:1 })),
          minMatch(/[0-9]/g, 1, this.$t('inputs.password.rules.number',{ n:1 })),
          minMatch(/[!@#$%^&*)(+=._-]/g, 1, this.$t('inputs.password.rules.symbol',{ n:1 })),
          minLength( 8, this.$t('inputs.password.rules.min',{ n:8 }))
        ],
        password2: [
          required( this.$t('inputs.repeatPassword.rules.required')),
          sameAs( 'password', this, this.$t('inputs.repeatPassword.rules.sameAs'))
        ]
      };
    }
  },
  watch: {
    $route: 'getQuery'
  },
  methods: {
    ...mapActions( 'auth', [ 'resetPass' ]),
    ...mapActions( 'app', [ 'alert' ]),
    validate() {
      return this.$refs.form && this.$refs.form.validate();
    },
    resetValidation() {
      return this.$refs.form && this.$refs.form.resetValidation();
    },
    reset() {
      return this.$refs.form && this.$refs.form.reset();
    },
    getQuery() {
      this.code = this.$route.query.code;
      this.email = this.$route.query.email;
    },
    onSubmit() {
      if ( this.loading ) return;
      if ( this.validate()) {
        const { password, code, email } = this;
        this.loading = true;
        this.resetPass({ email, code, password }).then(() => {
          this.success = true;
        }).catch( err => {
          this.alert({
            type: 'error',
            message: err.message
          });
        }).finally(() => {
          this.loading = false;
        });
      }
    }
  },
  beforeMount() {
    this.getQuery();
  }
}
</script>
